<template>
    <div>
        <ui-dropzone
            v-if="type === 'file'"
            v-model="valueComputed"
            v-bind="{ ...$attrs, ...$props }"
            :id="$attrs.id.replace('_', '-')"
            :accept="$attrs.rules.ext ? $attrs.rules.ext.join(',') : null"
            :field="validator"
            v-on="$listeners"
        />
        <ui-select
            v-else-if="type === 'select'"
            v-model="valueComputed"
            v-bind="{ ...$attrs }"
            :value="$attrs.value"
            :field="validator"
            :options="optionsComputed"
            :loading="loading"
            :option-label="$attrs.option_text || 'name'"
            :option-value="$attrs.option_value || 'value'"
            :multiple="$attrs.type === 'multiple'"
        />
        <autocomplete
            v-else-if="type === 'autocomplete'"
            v-bind="{ ...$attrs }"
            :model.sync="valueComputed"
            :value="$attrs.value"
            :field="validator"
            :options="optionsComputed"
            :loading="loading"
            :option-text="$attrs.option_text"
        />
        <ui-input
            v-else
            trim
            v-model="valueComputed"
            v-on="$listeners"
            v-bind="{ ...$attrs, ...$props }"
            :id="`field-${id}`"
            :list="`list-${id}`"
            :field="validator"
        />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import Autocomplete from '@/components/ui/inputs/Autocomplete.vue';
import UiDropzone from '@/components/ui/inputs/Dropzone.vue'
import UiInput from '@/components/ui/inputs/Input.vue'
import UiSelect from '@/components/ui/inputs/Select.vue';

export default {
    components: {
        Autocomplete,
        UiDropzone,
        UiInput,
        UiSelect,
    },
    props: {
        data: {
            type: String,
            default: null,
            required: false,
        },
        type: {
            type: String,
            default: 'text',
        },
        model: {
            type: null,
            default() {
                return this.type === 'file' ? null : '';
            },
        },
        provider: {
            type: String,
            default: null,
        },
        source: {
            type: String,
            default: '',
            required: false,
        },
        validator: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            id: this.makeID(10),
            loading: true,
            options: [],
        };
    },
    computed: {
        ...mapState(['verifications']),
        valueComputed: {
            set(value) {
                this.$emit('update:model', value);
                this.$emit('change', value);

                const events = this.$attrs.on_change;

                if (events) {
                    this.triggerChangeEvents(events, value);
                }
            },
            get() {
                return this.model;
            },
        },
        isRequired() {
            return this._.get(this.$attrs, 'rules.required') || this.$attrs.required;
        },
        optionsComputed() {
            if (this.type !== 'select' && this.type !== 'autocomplete') {
                return [];
            }
            
            if (this.source) {
                return this.options;
            }

            const data_key = this.data.replace(/[{}]/g, '');

            return this._.get(this.verifications, data_key);
        },
    },
    mounted() {
        if (this.type === 'select') {
            this.getOptions();
            return;
        }

        if (this.model) {
            const events = this.$attrs.on_change;

            if (events) {
                this.triggerChangeEvents(events, this.model, false);
            }
        }

        this.loading = false;
    },
    methods: {
        ...mapActions({
            updateContextValue: 'verifications/updateContextValue',
            updateValue: 'verifications/updateValue',
            deleteValue: 'verifications/deleteValue',
        }),
        mustache(string, data = {}) {
            return Object.entries(data).reduce((res, [key, value]) => res.replace(new RegExp(`{{\\s*${key}\\s*}}`, 'g'), value), string);
        },
        async getOptions() {
            try {
                this.loading = true;

                if (this.source) {
                    const source_variables = this.source.match(/(?<=\{\{)([\w.-]+)(?=\}\})/g);
                    const variables_to_replace = {};
                    let options_source = this.source;

                    if (source_variables) {
                        source_variables.forEach(source_variable => {
                            variables_to_replace[source_variable] = this._.get(this.verifications, source_variable)
                        });
    
                        options_source = this.mustache(options_source, variables_to_replace);
                    }

                    const { data } = await this.axiosAccount.get(options_source);

                    this.options = data;
                }

                if (this.model) {
                    const events = this.$attrs.on_change;

                    if (events) {
                        this.triggerChangeEvents(events, this.model, false);
                    }
                }
            } catch (error) {
                this.showError(error);
            } finally {
                this.loading = false;
            }
        },
        async triggerChangeEvents(events, value, clear_values = true) {
            const request_events = events.filter(event => event.action === 'request');
            const context_events = events.filter(event => event.action === 'set_temporal_context');
            const value_events = events.filter(event => event.action === 'set_values');
            const clear_values_events = events.filter(event => event.action === 'clear_dependent_values');
            const response_events = {};

            for (const event of request_events) {
                const source_variables = event.url.match(/(?<=\{\{)([\w.-]+)(?=\}\})/g);
                const variables_to_replace = {};

                source_variables.forEach(source_variable => {
                    const source_prefix = source_variable.slice(0, source_variable.indexOf('.'));
                    let source_values = response_events;

                    if (['context', 'values'].includes(source_prefix)) {
                        source_values = this.verifications
                    }
                    
                    variables_to_replace[source_variable] = this._.get(source_values, source_variable);
                });

                variables_to_replace[`values.${this.$attrs.id}`] = value;
                
                const formatted_source = this.mustache(event.url, variables_to_replace);
                let data = await this.axiosAccount.get(formatted_source)
                    .then(response => response.data)
                    .catch(() => []);

                if (event.data_path) {
                    data = this._.get(data, event.data_path);
                }

                response_events[event.name] = data;
            }

            for (const event of context_events) {
                Object.entries(event.values).forEach(([id, value]) => {
                    this.updateContextValue({key: id, value: this._.get(response_events, value)})
                });
            }
            
            if (clear_values) {
                for (const event of value_events) {
                    Object.entries(event.values).forEach(([id, path]) => {
                        const input_value = this._.get(response_events, path);
                        
                        this.updateValue({key: id, value: input_value});
                    });
                }

                for (const event of clear_values_events) {
                    event.values.forEach(value => {
                        this.deleteValue(value);
                    });
                }
            }
        },
    },
};
</script>
