import i18n from '@/i18n';
import { required, email, helpers, minValue, maxValue, minLength, maxLength } from '@vuelidate/validators';

const maxFileSize = sizeMB =>
    helpers.withParams({ type: 'maxFileSize', value: sizeMB }, value => {
        if (!value) return true;

        const fileSizeinMB = value.size / 1024 / 1024;
        return fileSizeinMB <= sizeMB;
    });

const allowedExtensions = extensions =>
    helpers.withParams({ type: 'allowedExtensions', value: extensions }, value => {
        if (!value) return true;

        return extensions.includes(value.type);
    });

const regex = pattern => helpers.regex(new RegExp(pattern));

const formUtils = {
    getFieldValidations(field) {
        try {
            const available_rules = ['required', 'email', 'min', 'max', 'pattern', 'size', 'ext'];
            const field_rules = {};
            available_rules.forEach(rule => {
                const fieldRule = field[rule] || field?.rules[rule];
                if (fieldRule) field_rules[rule] = fieldRule;
            });
            const validations = {};

            const rules = {
                required: { rule: helpers.withMessage(i18n.t('This field is required'), required), condition: field_rules.required },
                email: { rule: helpers.withMessage(i18n.t('Email invalid'), email), condition: field.type === 'email' },
                pattern: { rule: helpers.withMessage(i18n.t('Invalid format'), regex(field_rules.pattern)), condition: field_rules.pattern },
                minValue: { rule: helpers.withMessage(({ $params }) => i18n.t('The minimum value must be {min}', $params), minValue(field_rules.min)), condition: field.type === 'number' && field_rules.min },
                maxValue: { rule: helpers.withMessage(({ $params }) => i18n.t('The maximum value must be {max}', $params), maxValue(field_rules.max)), condition: field.type === 'number' && field_rules.max },
                minLength: { rule: helpers.withMessage(({ $params }) => i18n.t('This field must be a minimum of {min} characters', $params), minLength(field_rules.min)), condition: field.type !== 'number' && field_rules.min },
                maxLength: { rule: helpers.withMessage(({ $params }) => i18n.t('This field must have a maximum of {max} characters', $params), maxLength(field_rules.max)), condition: field.type !== 'number' && field_rules.max },
                size: { rule: helpers.withMessage(({ $params }) => i18n.t('The file must not be larger than {value} MB', $params), maxFileSize(field_rules.size)), condition: field.type === 'file' && field_rules.size },
                ext: { rule: helpers.withMessage(({ $params }) => i18n.t('The file must be of the type: {value}', { value: $params.value.join(', ') }), allowedExtensions(field_rules.ext)), condition: field.type === 'file' && field_rules.ext },
            };

            for (const [key, value] of Object.entries(rules)) {
                if (value.condition) {
                    validations[key] = value.rule;
                }
            }
            return validations;
        } catch (error) {
            return {};
        }
    },
};

export default formUtils;
