<template>          
    <div id="sumsub-websdk-container"></div>
</template>

<script type='text/javascript'>
import sumsub from '@sumsub/websdk';
import { mapGetters } from 'vuex';

export default {
    name: 'SumSub',
    props: {
        verification: {
            type: Object,
            default: null,
            required: true,
        },
    },
    computed: {
        ...mapGetters('ui', ['language']),
    },
    mounted() {
        this.launch();
    },
    watch: {
        language() {
            this.launch();
        },
    },
    methods: {
        async getAccessToken() {
            try {
                const { data } = await this.axiosAccount.post(`/api/verifications/${this.verification.id}/token`);
                return data;
            } catch (error) {
                this.showError(error);
            }
        },
        async launch() {
            const { email } = this.verification.provider;
            const { token } = await this.getAccessToken();
            const config = {
                email,
                lang: this.language,
                uiConf: {
                    customCssStr: `
                        :root {
                            --black: #000000;
                            --grey: #F5F5F5;
                            --grey-darker: #B2B2B2;
                            --border-color: #DBDBDB;
                            --section-shadow-color: none;
                        }

                        .sumsub-logo {
                            margin-bottom: 0;
                        }
                        .iframe2.min-height {
                            min-height: auto;
                        }
                        section {
                            margin-top: 0;
                        }
                    `,
                },
            };

            const sumsub_instance = sumsub
                .init(token, () => this.getAccessToken())
                .withConf(config)
                .withOptions({ addViewportTag: true, adaptIframeHeight: true })
                .on('idCheck.onReady', () => this.$emit('update:service-loading', false))
                .on('idCheck.onApplicantSubmitted', () => {
                    this.$emit('redirecToStatusPage', {
                        type: 'success',
                        title: '¡Form sent successfully!',
                        message: 'The form was successfully submitted for review, when the review is complete we will contact you.',
                    });
                })
                .on('idCheck.onApplicantResubmitted', () => {
                    this.$emit('redirecToStatusPage', {
                        type: 'success',
                        title: '¡Form sent successfully!',
                        message: 'The form was successfully submitted for review, when the review is complete we will contact you.',
                    });
                })
                .on('idCheck.applicantStatus', data => {
                    if (data?.reviewStatus === 'completed' && data.reviewResult?.reviewAnswer === 'GREEN') {
                        this.$emit('redirecToStatusPage', {
                            type: 'success',
                            title: '¡Form sent successfully!',
                            message: 'The form was successfully submitted for review, when the review is complete we will contact you.',
                        });
                    }

                    if (data?.reviewStatus === 'completed' && data.reviewResult?.reviewAnswer === 'RED' && data.reviewResult?.reviewRejectType === 'FINAL') {
                        this.$emit('redirecToStatusPage', {
                            type: 'error',
                            title: '¡Application rejected!',
                            message: 'Your application was rejected by the provider.'
                        });
                    }
                })
                .build();

            sumsub_instance.launch('#sumsub-websdk-container');
        },
    },
};
</script>
