<template>
    <div class="verification-form w-100">
        <div v-if="loading" class="text-center py-5">
            <b-spinner />
        </div>
        <b-form v-else id="form" @submit.prevent="handleSubmit">
            <div class="verification-form__accordion">
                <div v-for="(section, index) in formSteps" :key="section.id" class="verification-form__accordion-item">
                    <button
                        class="verification-form__accordion-item-header"
                        type="button"
                        :class="{ 'verification-form__accordion-item-header--active': index + 1 === currentStep }"
                        :disabled="index + 1 > currentStep && !(lastStep === 0 && index + 1 === 1) &&  index + 1 > lastStep"
                        @click="openStep(index + 1)"
                    >
                        <div class="verification-form__accordion-item-header-content">
                            <div
                                class="verification-form__accordion-item-counter"
                                :class="{
                                    'verification-form__accordion-item-counter--active': index + 1 === currentStep,
                                    'verification-form__accordion-item-counter--disabled': index + 1 > currentStep,
                                }">
                                {{ index + 1 }}
                            </div>
                            <h5 class="verification-form__accordion-item-header-title">
                                {{ $t(section.title) }}
                            </h5>
                            <span v-if="index + 1 <= lastStep" class="verification-form__icon-complete fas fa-check-circle"></span>
                        </div>
                        <span class="fas fa-chevron-down"></span>
                    </button>
                    <div class="verification-form__accordion-item-body" :class="[{ 'd-none': index + 1 !== currentStep }]">
                        <p class="verification-form__step-description">
                            {{ $t(section.description) }}
                        </p>
                        <div class="row">
                            <div v-for="field in section.fields" :key="field.id" class="col-md-6 mb-3 px-2">
                                <field
                                    v-bind="field"
                                    :name="field.name"
                                    :type="field.type"
                                    :model.sync="formData[field.id]"
                                    :validator="v$.formData[field.id]"
                                    :label="$t(field.label)"
                                    :placeholder="$t(field.placeholder)"
                                    :description="$t(field.description)"
                                />
                                <div v-if="field.type === 'file' && defaultFiles[field.id]" class="my-2">
                                    <p class="text-muted">
                                        {{ $t('Previously uploaded file:') }}
                                        <br />
                                        <a class="text-break d-flex" v-sanitize-href="defaultFiles[field.id].value" target="_blank" rel="noopener">
                                            <span class="mt-1 mr-1 fas" :class="'fas fa-file-image'"></span>
                                            {{ defaultFiles[field.id].file_name }}
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-end">
                            <div class="col-auto p-1">
                                <ui-button
                                    type="submit"
                                    variant="accent"
                                    class="mb-0"
                                    :loading="saving"
                                >
                                    {{ $t('Continue') }}
                                </ui-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-form>
        <ui-modal ref="confirmationModal" centered :title="$t('Confirm sending information')">
            <p v-sanitize="$t('Thank you for completing the form. It is important that all information provided is correct to expedite the approval of your account. If not, click <strong>Cancel</strong> and review the information.')"></p>
            <p class="mb-0" v-sanitize="$t('Once you click on <strong>Continue</strong>, the legal representative verification process will begin.')"></p>
            <template #footer>
                <ui-button variant="secondary" :disabled="saving" @click="$refs.confirmationModal.close()">
                    {{ $t('Cancel') }}
                </ui-button>
                <ui-button variant="primary" :loading="saving" @click="submitForm">
                    {{ $t('Continue') }}
                </ui-button>
            </template>
        </ui-modal>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { useVuelidate } from '@vuelidate/core';

import Field from '@/components/ui/inputs/Any.vue';
import UiButton from '@/components/ui/buttons/Button.vue';
import UiModal from '@/components/ui/modal/Modal.vue';

import formsUtils from '@/utils/forms.utils';

export default {
    name: 'VerificationForm',
    components: {
        Field,
        UiButton,
        UiModal,
    },
    props: {
        verification: {
            type: Object,
            default: null,
            required: true,
        },
    },
    setup() {
        return {
            v$: useVuelidate(),
        };
    },
    data() {
        return {
            currentStep: 1,
            defaultFiles: {},
            formSteps: [],
            lastStep: 0,
            loading: false,
            saving: false,
            error: null,
        };
    },
    computed: {
        ...mapState('verifications', {
            values: state => state.values,
        }),
        rules() {
            const validations = { formData: {} };

            if (this.formSteps.length) {
                const section = this.formSteps[this.currentStep - 1];
    
                section.fields.forEach((field) => {
                    const field_config = { ...field };
    
                    if (field.type === 'file' && this.defaultFiles[field.id]) {
                        delete field_config.rules.required;
                    }
    
                    validations.formData[field.id] = formsUtils.getFieldValidations(field_config);
                });
            }

            return validations;
        },
        fileFields() {
            const file_fields = {};
            
            const { form } = this.verification.forms;

            if (form?.length) {
                form.forEach(form => {
                    form.fields.forEach(field => {
                        if (field.type === 'file') {
                            file_fields[field.id] = true;
                        }
                    });
                })
            }

            return file_fields;
        },
        formData: {
            get: function () {
                return this.values;
            },
            set: function (newValue) {
                this.updateValues(newValue);
            },
        },
    },
    watch: {
        verification() {
            this.loadForm();
        },
    },
    validations() {
        return this.rules;
    },
    mounted() {
        this.loadForm();
    },
    methods: {
        ...mapActions({ updateValues: 'verifications/updateValues' }),
        openStep(step) {
            this.currentStep = step;
        },
        handleSubmit() {
            this.v$.formData.$touch();

            if (this.v$.formData.$invalid) {
                return;
            }

            if (this.currentStep === this.formSteps.length) {
                this.$refs.confirmationModal.show()
                return;
            }

            this.submitForm();
        },
        async loadForm() {
            const { forms, information, last_step } = this.verification
            this.formSteps = forms.form;

            if (information.length) {
                const form_data = {};
                const default_files = {};

                information.forEach(field => {
                    if (this.fileFields[field.id])  {
                        default_files[field.id] = field;
                    } else {
                        form_data[field.id] = field.value;
                    }
                });

                this.defaultFiles = default_files;
                this.formData = form_data;
            }

            this.lastStep = last_step;
        },
        async getFormData() {
            const file_requests = [];
            const formatted_data = { information: [], step: this.currentStep };

            Object.entries(this.formData).forEach(([id, value]) => {
                if (value instanceof File) {
                    const file_data = new FormData();
                    file_data.append('input', id);
                    file_data.append('file', value);
                    file_data.append('path', 'verifications');

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    };

                    file_requests.push(
                        this.axiosAccount.post(
                            `/api/verifications/${this.verification.id}/files`,
                            file_data,
                            config
                        )
                    );
                } else {
                    formatted_data.information.push({ id, value });
                }
            });

            if (file_requests.length) {
                const file_responses = await Promise.all(file_requests);
    
                file_responses.forEach(response => {
                    const { data } = response;

                    formatted_data.information.push({
                        id: data.input,
                        value: data.path,
                        mime_type: data.mime_type,
                        file_name: data.name,
                    });
                });
            }

            Object.entries(this.defaultFiles).forEach(([id, value]) => {
                const file_input = formatted_data.information.find(field => field.id === id);

                if (!file_input || file_input?.value === null) {
                    formatted_data.information.push(value);
                }
            });

            return formatted_data;
        },
        async submitForm() {
            try {
                this.saving = true;

                const formData = await this.getFormData();
                const { data } = await this.axiosAccount.put(`/api/verifications/${this.verification.id}`, formData);
                const { last_step } = data;

                this.lastStep = last_step;

                if (this.currentStep === this.formSteps.length) {
                    this.$emit('redirecToStatusPage', {
                        type: 'success',
                        title: '¡Form sent successfully!',
                        message: 'The form was successfully submitted for review, when the review is complete we will contact you.',
                    });
                }

                if (this.currentStep < this.formSteps.length) {
                    this.currentStep += 1;
                }
            } catch (error) {
                this.showError(error);
            } finally {
                this.saving = false;
            }
        },
    },
};
</script>

<style lang="scss">
.verification-form {
    &__accordion {
        margin-bottom: 16px;
    }

    &__accordion-item-header {
        align-items: center;
        background-color: $white;
        color: $ecart-pay-secondary-500;
        border: 0;
        border-bottom: 1px solid $ecart-pay-secondary-300;
        display: flex;
        padding: 20px 8px;
        width: 100%;

        &:disabled {
            color: $ecart-pay-secondary-400;
        }

        &--active {
            color: $ecart-pay-secondary-600;
        }
    }

    &__accordion-item-header-content {
        align-items: center;
        display: flex;
        gap: 10px;
        width: 100%;
    }

    &__accordion-item-header-title {
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 0;
    }

    &__accordion-item-counter {
        align-items: center;
        background-color: $ecart-pay-secondary-500;
        border-radius: 8px;
        color: $white;
        display: flex;
        font-size: 0.75rem;
        font-weight: 600;
        height: 24px;
        justify-content: center;
        width: 24px;

        &--active {
            background-color: $ecart-pay-secondary-600;
        }

        &--disabled {
            background-color: $ecart-pay-secondary-400;
        }
    }

    &__icon-complete {
        color: $general-success;
        font-size: 16px;
    }

    &__accordion-item-body {
        padding: 24px 8px 0 8px;
    }

    &__step-description {
        color: $ecart-pay-secondary-500;
        font-weight: 400;
        font-size: 0.9rem;
    }
}
</style>
