var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui-dropzone"},[(_vm.label)?_c('legend',{staticClass:"ui-dropzone__label"},[_vm._v("\n        "+_vm._s(_vm.label)+"\n        "),(_vm.isOptional)?_c('span',{staticClass:"text-muted"},[_vm._v("("+_vm._s(_vm.$t('Optional'))+")")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"ui-dropzone__container",class:{
            'ui-dropzone__container--active': _vm.active,
            'ui-dropzone__container--valid': _vm.field && _vm.field.$dirty && !_vm.field.$error,
            'ui-dropzone__container--invalid': _vm.field && _vm.field.$dirty && _vm.field.$error,
            'ui-dropzone__container--has-value': _vm.value,
        },on:{"dragenter":function($event){$event.preventDefault();return _vm.toggleActive.apply(null, arguments)},"dragleave":function($event){$event.preventDefault();return _vm.toggleActive.apply(null, arguments)},"dragover":function($event){$event.preventDefault();},"drop":function($event){$event.preventDefault();return _vm.drop.apply(null, arguments)},"click":_vm.handleDropzoneClick}},[(_vm.value)?_c('div',{staticClass:"ui-dropzone__preview-container"},[_vm._m(0),_c('div',{staticClass:"ui-dropzone__content"},[_c('h6',{staticClass:"ui-dropzone__file-name"},[_vm._v("\n                    "+_vm._s(_vm.value.name)+"\n                ")]),_c('p',{staticClass:"ui-dropzone__file-type"},[_vm._v("\n                    "+_vm._s(_vm.value.type)+"\n                ")])]),_c('button',{staticClass:"ui-dropzone__delete-button",attrs:{"type":"button"},on:{"click":function($event){$event.stopPropagation();return _vm.clearInput.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-times-circle"})])]):[_vm._m(1),_c('h6',{staticClass:"ui-dropzone__title"},[_vm._v(_vm._s(_vm.$t('Drag your file here')))]),_c('p',{staticClass:"ui-dropzone__text"},[_vm._v(_vm._s(_vm.$t('or click to select from'))+" "),_c('strong',[_vm._v(_vm._s(_vm.$t('directory')))])]),_c('input',{ref:"fileInput",staticClass:"ui-dropzone__input",attrs:{"type":"file"},on:{"change":_vm.handleChange}})]],2),_c('ui-invalid-feedback',{attrs:{"field":_vm.field}}),(_vm.description)?_c('div',{staticClass:"ui-dropzone__description"},[_vm._v("\n        "+_vm._s(_vm.description)+"\n    ")]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui-dropzone__preview"},[_c('span',{staticClass:"fas fa-file"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui-dropzone__icon-container"},[_c('span',{staticClass:"ui-dropzone__icon fas fa-file"})])
}]

export { render, staticRenderFns }