<template>
    <div class="ui-dropzone">
        <legend v-if="label" class="ui-dropzone__label">
            {{ label }}
            <span v-if="isOptional" class="text-muted">({{ $t('Optional') }})</span>
        </legend>
        <div
            class="ui-dropzone__container"
            :class="{
                'ui-dropzone__container--active': active,
                'ui-dropzone__container--valid': field && field.$dirty && !field.$error,
                'ui-dropzone__container--invalid': field && field.$dirty && field.$error,
                'ui-dropzone__container--has-value': value,
            }"
            @dragenter.prevent="toggleActive"
            @dragleave.prevent="toggleActive"
            @dragover.prevent
            @drop.prevent="drop"
            @click="handleDropzoneClick"
        >
            <div v-if="value" class="ui-dropzone__preview-container">
                <div class="ui-dropzone__preview">
                    <span class="fas fa-file"></span>
                </div>
                <div class="ui-dropzone__content">
                    <h6 class="ui-dropzone__file-name">
                        {{ value.name }}
                    </h6>
                    <p class="ui-dropzone__file-type">
                        {{ value.type }}
                    </p>
                </div>
                <button type="button" class="ui-dropzone__delete-button" @click.stop="clearInput">
                    <i class="fas fa-times-circle"></i>
                </button>
            </div>
            <template v-else>
                <div class="ui-dropzone__icon-container">
                    <span class="ui-dropzone__icon fas fa-file"></span>
                </div>
                <h6 class="ui-dropzone__title">{{ $t('Drag your file here') }}</h6>
                <p class="ui-dropzone__text">{{ $t('or click to select from') }} <strong>{{ $t('directory') }}</strong></p>
                <input ref="fileInput" class="ui-dropzone__input" type="file" @change="handleChange" />
            </template>
        </div>
        <ui-invalid-feedback :field="field" />
        <div v-if="description" class="ui-dropzone__description">
            {{ description }}
        </div>
    </div>
</template>

<script>
import UiInvalidFeedback from '@/components/ui/InvalidFeedback.vue';

export default {
    name: 'UiDropzone',
    components: { UiInvalidFeedback },
    props: {
        description: {
            type: String,
            default: null,
            required: false,
        },
        field: {
            type: Object,
            default: null,
            required: false,
        },
        label: {
            type: String,
            required: true,
        },
        value: {
            type: File,
            default: null,
            required: false,
        },
    },
    data() {
        return {
            active: false,
        };
    },
    computed: {
        isOptional() {
            return !this.field?.hasOwnProperty('required');
        },
    },
    methods: {
        toggleActive() {
            this.active = !this.active;
        },
        drop(event) {
            this.$emit('input', event.dataTransfer.files[0]);
            this.toggleActive();
        },
        handleChange(event) {
            this.$emit('input', event.target.files[0]);
        },
        clearInput() {
            this.$emit('input', null);
        },
        handleDropzoneClick() {
            if (!this.value) {
                this.$refs.fileInput.click()
            }
        }
    },
};
</script>

<style lang="scss">
.ui-dropzone {
    &__label {
        color: $general-black;
        font-size: 0.9rem;
        font-weight: 500;
    }

    &__container {
        align-items: center;
        border: 1.388px dashed $accounts-primary;
        border-radius: 8px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        height: 180px;
        justify-content: center;
        padding: 30px;

        &--active {
            background-color: rgba($accounts-primary, 0.1);
        }

        &--valid {
            border-color: $general-success;
        }

        &--invalid {
            border-color: $general-error;
        }

        &--has-value {
            cursor: initial;
        }
    }

    &__icon-container {
        align-items: center;
        background-color: #F4F7FB;
        border-radius: 100%;
        display: flex;
        height: 55px;
        justify-content: center;
        margin-bottom: 15px;
        width: 55px;
    }

    &__icon {
        font-size: 23px;
        color: $accounts-primary;
    }

    &__title {
        color: $general-black;
        font-weight: 600;
        margin-bottom: 5px;
    }

    &__text {
        color: $general-black;
        margin-bottom: 0;

        strong {
            color: #FC6F2A;
        }
    }

    &__input {
        display: none;
    }

    &__preview-container {
        align-items: center;
        display: flex;
        border-radius: 8px;
        gap: 8px;
        padding: 16px;
        width: 100%;
    }

    &__preview {
        align-items: center;
        background-color: $accounts-primary;
        border-radius: 8px;
        color: $white;
        display: flex;
        flex-shrink: 0;
        font-size: 23px;
        height: 55px;
        justify-content: center;
        width: 55px;
    }

    &__content {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        width: calc(100% - 93px);
    }

    &__file-name {
        color: $general-black;
        margin-bottom: 0;
        font-size: 0.95rem;
        font-weight: 600;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }

    &__file-type {
        color: $general-black;
        font-size: 0.85rem;
        font-weight: 400;
        margin-bottom: 0;
        max-width: 100%;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }

    &__delete-button {
        align-items: center;
        background-color: transparent;
        border: 0;
        border-radius: 100%;
        color: $general-error;
        display: flex;
        font-size: 22px;
        height: 22px;
        justify-content: center;
        line-height: 1;
        margin-top: 5px;
        transition: 200ms ease-out;
        width: 22px;

        &:hover {
            color: rgba($general-error, 0.7);
        }
    }

    &__description {
        color: $accounts-secondary-400;
        font-size: 0.85rem;
        margin-top: 5px;
    }
}
</style>