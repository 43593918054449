const IN_REVIEW = 'in_review';
const ACCEPTED = 'accepted';
const REJECTED = 'rejected';
const PENDING = 'pending';
const CREATED = 'created';

export {
    IN_REVIEW,
    ACCEPTED,
    REJECTED,
    PENDING,
    CREATED,
};
