<template>
    <div class="verification-page">
        <div class="text-center">
            <router-link to="/">
                <img
                    src="@/assets/images/logos/accounts-logo.svg"
                    class="verification-page__logo img-fluid"
                    alt="Tendencys"
                />
            </router-link>
            <h1 v-if="!error" class="verification-page__title">
                {{ $t('Identity validation') }}
            </h1>
        </div>
        <div v-if="loading" class="text-center py-5">
            <b-spinner />
        </div>
        <template v-else>
            <empty-state v-if="error" :class="error.class || ''" :title="error.title" :text="error.text" :img="error.image || 'bad-connection.svg'" img-size="md" />
            <component v-else :is="currentForm" v-bind="currentBindings" @redirecToStatusPage="redirecToStatusPage" />
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import EmptyState from '@/components/ui/EmptyState.vue';
import SumSub from '@/components/verifications/providers/SumSub.vue'
import VerificationForm from '@/components/forms/Verification.vue';

import iframeMixin from '@/plugins/mixin/iframe.mixin';
import supportChatUtils from '@/utils/support-chat.utils';

import { IN_REVIEW, ACCEPTED, REJECTED } from '@/constants/verification-statuses';

export default {
    name: 'verification-page',
    components: {
        EmptyState,
        SumSub,
        VerificationForm,
    },
    mixins: [iframeMixin],
    data() {
        return {
            currentBindings: {},
            currentForm: null,
            error: null,
            loading: false,
            verificationId: null,
        }
    },
    computed: {
        ...mapState(['language']),
    },
    watch: {
        language() {
            this.getVerification();
        },
    },
    mounted() {
        this.verificationId = this.$route.query.id;

        if (!this.verificationId) {
            this.error = {
                title: `¡${this.$t('Something went wrong')}!`,
                text: this.$t('The request is required to have the {parameter} parameter.', { parameter: 'id' }),
            };
        } else {
            this.getVerification();
        }

        if (this.itsAnIframe) {
            supportChatUtils.hideChat();
        }

        this.init();
    },
    methods: {
        showGeolocationError(text) {
            this.error = {
                image: 'location-error.svg',
                title: `¡${this.$t('Something went wrong')}!`,
                class: 'verification-page__empty-state-location',
                text,
            };
        },
        async init() {
            const error_messages = {
                1: 'You must enable location permissions to use this page. To do this you can click on the location icon in your address bar.',
                2: 'There was an error trying to get your location, try reloading the page.',
                3: 'A timeout error occurred while trying to obtain your location. Try reloading the page.',
            };

            try {
                this.loading = true;

                const result = await navigator.permissions.query({ name: 'geolocation' })
                
                if (result.state === 'prompt' || result.state === 'denied') {
                    this.showGeolocationError(this.$t(error_messages[1]));

                    const onSuccess = () => { this.error = null; };
                    const onError = (error) => { this.showGeolocationError(this.$t(error_messages[error.code])); }

                    navigator.geolocation.getCurrentPosition(
                        onSuccess,
                        onError,
                        { enableHighAccuracy: false, maximumAge: 0, timeout: Infinity }
                    );
                }

                result.addEventListener('change', () => {
                    if (result.state === 'granted') {
                        this.error = null;
                    }

                    if (result.state === 'denied') {
                        this.showGeolocationError(this.$t(error_messages[1]));
                    }
                });
            } catch(error) {
                this.showGeolocationError(this.$t(error_messages[2]));
            } finally {
                this.loading = false;
            }
        },
        handleError() {
            this.error = {
                title: `¡${this.$t('Something went wrong')}!`,
                text: this.$t('You may also refresh the page or try again later'),
            };
        },
        redirecToStatusPage({ type, title, message }) {
            const formatted_message = JSON.stringify({ title, message });
            const encoded_message = Buffer.from(formatted_message).toString('base64');

            this.$router.push({
                name: 'verificationStatusPage',
                params: { status: type },
                query: { _v: encoded_message },
            });
        },
        getRedirectStatus(status) {
            if (status === IN_REVIEW) {
                return {
                    type: 'info',
                    title: 'Application in review',
                    message: 'Your application is being reviewed by the provider. This can take between 1 to 10 minutes.'
                };
            }

            if (status === ACCEPTED) {
                return {
                    type: 'success',
                    title: '¡Approved application!',
                    message: 'Your application was approved. Now you can use the system.'
                };
            }

            if (status === REJECTED) {
                return {
                    type: 'error',
                    title: '¡Application rejected!',
                    message: 'Your application was rejected by the provider.'
                };
            }

            return null;
        },
        loadFormComponent(verification) {
            const available_services = { sumsub: 'SumSub', verification_form: 'VerificationForm' };

            if (verification.provider) {
                const { service } = verification.provider;
                this.currentForm = available_services[service];
            }

            if (verification?.forms?.form?.length) {
                this.currentForm = available_services.verification_form;
            }

            this.currentBindings = { verification };
        },
        async getVerification() {
            try {
                this.loading = true;

                const config = { headers: { 'Accept-Language': this.language } };
                const { data } = await this.axiosAccount.get(`/api/verifications/${this.verificationId}`, config);
                const redirect_status = this.getRedirectStatus(data.status);

                if (redirect_status) {
                    this.redirecToStatusPage(redirect_status);
                } else {
                    this.loadFormComponent(data);
                }
            } catch (error) {
                const status_code = error?.response?.status;

                if (status_code === 401)  {
                    this.$router.push({
                        name: 'accountsLogin',
                        query: { referer: `http://localhost:8080/${this.$router.currentRoute.fullPath}` },
                    });

                    return;
                }

                this.showError(error);
                this.handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang='scss'>
.verification-page {
    &__logo {
        margin-bottom: 45px;
        width: 200px;
    }

    &__title {
        font-size: 2.25rem;
        font-weight: 700;
        margin-bottom: 30px;
        color: $general-black;
    }

    &__text {
        color: #787F84;
        font-size: 16px;
        margin-bottom: 30px;
        font-weight: 400;

        b {
            font-weight: 600;
        }
    }

    &__empty-state-location {
        img {
            height: 200px;
            width: auto;
        }
    }
}
</style>
